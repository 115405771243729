@import '../../styles/customMediaQueries.css';

.backdrop {
  min-height: fit-content;
  max-width: 100vw;
  background: #584caf;
}

.backdropImage {
  height: 100%;
  background-size: cover;
  background-image: url(./images/Hero_Background.png);
  background-position: center;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  height: 100%;
}

.heroSections {
  grid-template-columns: repeat(1, 1fr);
  display: grid;
  height: 100%;
  width: 100%;
  gap: 1.5em;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.heroContent {
  padding: 0 2em;
  height: 100%;
  max-width: 100%;
}

.heroImage {
  max-height: 100%;
  max-width: 100%;
  margin: 2em 0;
  justify-content: center;
  align-items: center;
}

.heroSubSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  max-width: 100%;
}

.heroHeader {
  width: 100%;
  height: 50%;
  margin: 2em 0;
}


.heroInfo {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.text {
  color: var(--colorWhite);
  font-weight: var(--fontWeightMedium);
  font-family: var(--fontFamily);
  font-size: 1rem;
  text-align: justify;

  @media (--viewportMedium) {
    font-size: 1.125rem;
    width: 70%;
  }
}

.buttons {
  display: flex;
  align-items: center;

  @media (--viewportMedium) {
    width: 70%;
  }
}


.primaryButton {
  display: inline-block;
  background-color: #e9c24b;
  border-radius: var(--borderRadius);
  padding: 0.5em 1em;
  color: var(--colorBlack);
  font-weight: var(--fontWeightMedium);
  border: none;
  margin-right: 0.5em;

  @media (--viewportMedium) {
    margin-right: 2em;
  }
}

.primaryButton:hover {
  background-color: #f0d76b;
  cursor: pointer;
}

.ctaLink {
  color: #e9c24b;
}