@import '../../styles/customMediaQueries.css';

.container {
  background: #edebff;
  font-size: 1rem;
  width: 100%;
  min-height: 30%;
}

.heading {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 2rem 0;
  font-weight: var(--fontWeightRegular);
}

.scroller {
  width: 100%;
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  /* --_animation-direction: forwards; */
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 10s) var(--_animation-direction, forwards) linear
    infinite;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}
