@import '../../styles/customMediaQueries.css';

.backdrop {
  max-width: 100vw;
  min-height: fit-content;
  background: #edebff;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  height: 100%;
}

.sections {
  grid-template-columns: repeat(1, 1fr);
  display: grid;
  height: 100%;
  gap: 1.5em;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.ctaHeading {
  font-size: 2rem;
}

.ctaSubHeading {
  font-size: 1.5rem;
  margin: 0.5em 0;
}

.ctaInfo {
  font-size: 1rem;
  margin: 0;
  margin-bottom: 0.5em;
}

.ctaContent {
  padding: 2em;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.ctaSubContent {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: fit-content;
}

.ctaVideoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  height: 100%;
}

.ctaVideo {
  height: 100%;

  @media (--viewportMedium) {
    width: 100%;
    height: 80%;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.primaryButton {
  display: inline-block;
  background-color: #e9c24b;
  border-radius: var(--borderRadius);
  padding: 0.5em 1em;
  color: var(--colorBlack);
  font-weight: var(--fontWeightMedium);
  border: none;
  margin-right: 0.5em;

  @media (--viewportMedium) {
    margin-right: 2em;
  }
}

.primaryButton:hover {
  background-color: #f0d76b;
  cursor: pointer;
}
